import { mapGetters } from 'vuex';

export default {
	data () {
		return {
			editCalendarItemModal: {
				active: false,
				calendarItem: null
			},
			newCalendarItem: {
				title: '',
				description: '',
				start_date: '',
				end_date: '',
				theme_ids: [],
				url: ''
			},
			errorMessages: []
		}
	},
	computed: {
		...mapGetters({
			themes: 'theme/themes',
			calendarItems: 'calendarItem/calendarItems',
			isLoadingResources: 'isLoadingResources'
		})
	},
	created () {
		this.$store.dispatch('theme/fetchThemes');
		this.$store.dispatch('calendarItem/fetchCalendarItems');
	},
	methods: {
		createCalendarItem () {
			this.$store.dispatch('calendarItem/createCalendarItem', this.newCalendarItem).then(() => {
				this.errorMessages = []
				this.newCalendarItem = {
					title: '',
					description: '',
					start_date: '',
					end_date: '',
					theme_ids: [],
					url: ''
				}
				this.$notify({
					title: 'Agenda item toegevoegd',
					message: 'Agenda item is succesvol aangemaakt!',
					type: 'success',
					position: 'bottom-right'
				})
			}).catch((errors) => {
				this.errorMessages = errors;
			});
		},
		deleteCalendarItem (id) {
			this.$store.dispatch('calendarItem/deleteCalendarItem', id).then(() => {
				this.$notify({
					title: 'Agenda item verwijderd',
					message: 'Agenda item is succesvol verwijderd!',
					type: 'success',
					position: 'bottom-right'
				})
			});
		},
		editCalendarItem (calendarItem) {
			this.editCalendarItemModal = {
				active: true,
				calendarItem: {
					...calendarItem,
					theme_ids: calendarItem.themes.map(t => t.id)
				}
			}
		},
		updateCalendarItem () {
			this.$store.dispatch('calendarItem/updateCalendarItem', this.editCalendarItemModal.calendarItem).then(() => {
				this.editCalendarItemModal = {
					active: false,
					calendarItem: null
				}
				this.$notify({
					title: 'Agenda item bewerkt',
					message: 'Agenda item is succesvol bewerkt!',
					type: 'success',
					position: 'bottom-right'
				})
			})
		}
	}
}